<template>
  <v-container>
    <v-overlay
      v-if="loadingAuthentication"
      :value="loadingAuthentication"
      z-index="200"
      color="primary darken-5"
    >
      <v-progress-circular width="6" size="110" :color="'white'" indeterminate>
        <Logo
          product="conecta_control"
          height="55"
          noMargins
          white
          class="blink"
        />
      </v-progress-circular>

      <div class="text-body-1 font-weight-regular pt-6">
        {{ $ml.get("authenticating") }}...
      </div>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Callback",

  data: () => ({
    logo: require("@/assets/appslogos/icons/icon-control.png"),
    loadingAuthentication: true,
    authenticationError: null,
  }),

  computed: {
    ...mapGetters(["loadingSync", "authError"]),
  },

  methods: {
    ...mapActions([
      "generateToken",
      "exchangeToken",
      "logout",
      "syncUsersTask",
    ]),
    ...mapMutations(["setAuthError"]),

    goToLogin() {
      if (!this.loadingAuthentication) {
        this.$router.push({ name: "Login" });
      }
    },

    generateOrExchangeToken(path) {
      if (path.suiteKey) {
        return Promise.resolve(this.exchangeToken(path.suiteKey));
      }
      return Promise.resolve(this.generateToken(path));
    },

    routerPushNext(nextPage) {
      this.syncUsersTask();
      if (nextPage) {
        this.$router.push({ name: nextPage });
        localStorage.removeItem("nextPage");
      } else {
        this.$router.push({ name: "Home" });
      }
    },
  },

  mounted() {
    const path = Object.assign({}, this.$route.query);
    const nextPage = localStorage.getItem("nextPage");
    if (Object.keys(path).length) {
      this.$router.replace({ query: null });
      this.generateOrExchangeToken(path)
        .then(() => {
          this.routerPushNext(nextPage);
        })
        .catch((error) => {
          this.$router.push({ name: "Login" });
          this.setAuthError(error);
        })
        .finally(() => {
          this.loadingAuthentication = false;
        });
    } else {
      this.routerPushNext(nextPage);
    }
  },
};
</script>

<style lang="scss" scoped>
.icon-spinner {
  animation: spin-animation 1.5s infinite;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
